<template>
  <div class="dashboard-app--container">
    <loading-state
      v-if="iframeLoading"
      :message="$t('Carregando Integração...')"
      class="dashboard-app_loading-container"
    />
    <iframe
      v-if="mostrarIframe"
      :src="iframeUrl"
      @load="() => onIframeLoad()"
    />
  </div>
</template>
<script>
import { useAlert } from 'dashboard/composables';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LoadingState,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasOpenedAtleastOnce: false,
      iframeLoading: true,
      mostrarIframe: false,
      iframeUrl: '',
      typebotUrl: process.env.WOOTIFY_TYPEBOT_BUILDER,
      typebotgetLoginURL: process.env.WOOTIFY_TYPEBOT_LOGIN,
      cwpublic: process.env.WOOTIFY_CW_PUBLIC_URL,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      agentBots: 'agentBots/getBots',
      getAccount: 'accounts/getAccount',
    }),
  },
  watch: {
    /* isVisible() {
      if (this.isVisible) {
        this.hasOpenedAtleastOnce = true;
      }
    }, */
  },
  mounted() {
    /* window.onmessage = e => {
      if (
        typeof e.data !== 'string' ||
        e.data !== 'chatwoot-dashboard-app:fetch-info'
      ) {
        return;
      }
      this.onIframeLoad(0);
    }; */
    // this.analisarAgentBots();
    this.garanteToken();
  },
  methods: {
    onIframeLoad() {
      // const frameElement = document.getElementById(this.getFrameId(index));
      // const eventData = { event: 'appContext', data: this.dashboardAppContext };
      // frameElement.contentWindow.postMessage(JSON.stringify(eventData), '*');
      this.iframeLoading = false;
      setTimeout(() => {
        this.iframeUrl = this.typebotUrl;
      }, '1000');
    },

    async garanteToken() {
      // console.log('garanteToken');

      try {
        let html = await axios.get(this.typebotUrl + '/api/auth/signout', {
          headers: {
            'Content-Type': 'text/html',
          },
        });

        html = html.data;
        html = html.split('value="')[1].split('"')[0];
        // console.log("csrfToken: " + html );

        await axios.post(
          this.typebotUrl + '/api/auth/signout',
          { csrfToken: html },
          {
            headers: {
              'Content-Type': 'text/html',
            },
          }
        );

        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
        } = this.getAccount(this.accountId);

        const user = this.currentUser;

        const response = await axios.get(
          this.typebotgetLoginURL +
            '/?account_id=' +
            this.accountId +
            '&account_name=' +
            name +
            '&utoken=' +
            user.access_token +
            '&cwpublic=' +
            this.cwpublic,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        // console.log(response.data.url);

        this.iframeUrl = response.data.url;
        this.mostrarIframe = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.dashboard-app--container,
.dashboard-app--list,
.dashboard-app--list iframe {
  height: 100%;
  width: 100%;
}

.dashboard-app--list iframe {
  border: 0;
}
.dashboard-app_loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
